/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.min.css" // This to be added.
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import React from "react";

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    // Google Analytics g-tag
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-8T53C3LCVK`;
    script.async = true;
    document.head.appendChild(script);

    const script2 = document.createElement("script");
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-8T53C3LCVK');
    `;
    document.head.appendChild(script2);
  }
};